<template>
  <v-container fluid class="pt-0 pay-table-wrapper" style="height: 100vh;">
    <v-dialog max-width="80%" v-model="dialog">
      <v-card>
        <v-card-text style="font-weight: 500; font-size: 1.3rem" class="grey darken-2 py-4 white--text">
          Помилки при завантажені оплат... Відсутні абоненти
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-4">
          <div class="error-row" style="display: flex; padding: 0 2px; margin-bottom: 4px; background-color: #f1f1f1" v-for="(item, idx) in flat_errors" :key="idx">
            <div style="flex: 0 0 60px; padding: 5px 2px 5px 2px; text-align: center; background-color: #d7d7d7;">{{ idx + 1 }}</div>
            <div>
              <p class="mb-0" style="font-size: .95rem; font-style: oblique; padding: 5px 2px 5px 10px">
                {{
                  `Адреса: ${item.address}, Особовий: ${item.person_id}, Платник: ${item.payer}, Сума: ${item.sum}, Дата оплати: ${formatDate(item.pay_date)} `
                }}
              </p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="80%" v-model="details">
      <v-card v-if="selected_row">
        <v-card-text style="font-weight: 500; font-size: 1.3rem" class="grey darken-2 py-4 white--text">
          {{ `${formatDate(selected_row.pay_date)}. ${selected_row.bank_name}. ${selected_row.service_name}` }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-4">
          <div class="pay-table">
            <div class="pat-table-header">
              <div class="pay-table-row">
                <div class="col" style="flex: 0 0 50px;">
                  <v-checkbox color="success"
                              hide-details
                              class="pa-0 ma-0"
                              :ripple="false"
                              v-model="detail_check_all"
                              @change="detailCheckAllChange"
                  />
                </div>
                <div class="col" style="flex: 0 0 100px">Особовий</div>
                <div class="col" style="flex: 0 0 340px">Адреса</div>
                <div class="col" style="flex: 0 0 190px">Платник</div>
                <div class="col" style="flex: 0 0 90px">Сума</div>
                <div class="col" style="flex: 0 0 90px">Сума без.</div>
                <div class="col" style="flex: 0 0 70px">К-сть</div>
                <div class="col" style="flex: 1"></div>
              </div>
            </div>
            <div class="pay-table-body">
              <div class="pay-table-row" v-for="(item, idx) in details_items" :key="idx">
                <div class="col" style="flex: 0 0 50px;">
                  <v-checkbox
                      color="success"
                      v-model="item.selected"
                      hide-details
                      class="pa-0 ma-0"
                      @change="changeSelectDetail(item)"
                      :ripple="false"/>
                </div>
                <div class="col" style="flex: 0 0 100px">{{ item.person_id }}</div>
                <div class="col" style="flex: 0 0 340px">{{ item.address  }}</div>
                <div class="col" style="flex: 0 0 190px">{{ item.payer }}</div>
                <div class="col" style="flex: 0 0 90px">{{ item.pay_sum | formatNumber }}</div>
                <div class="col" style="flex: 0 0 90px">{{ item.pay_sum_without | formatNumber }}</div>
                <div class="col" style="flex: 0 0 70px">{{ item.pay_count }}</div>
                <div class="col" style="flex: 1"></div>
              </div>
            </div>
            <div class="pat-table-footer">
              <div class="pay-table-row">
                <div class="col" style="flex: 0 0 680px;">
                  Всього:
                </div>
                <div class="col" style="flex: 0 0 90px">
                  {{ getTotal('details_items', 'pay_sum') | formatNumber }}
                </div>
                <div class="col" style="flex: 0 0 90px">
                  {{ getTotal('details_items', 'pay_sum_without') | formatNumber }}
                </div>
                <div class="col" style="flex: 0 0 70px">
                  {{ getTotal('details_items','pay_count') | formatNumber }}
                </div>
                <div class="col" style="flex: 1"></div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row style="flex: 0">
      <v-col cols="12">
        <v-card class="mt-0 transparent" tile elevation="0">
          <v-row class="pt-3 pb-0">
            <v-col cols="6" sm="6" md="3">
              <MonthComponent
                  v-model="date_start"
                  :depressed="false"
                  :class_="'grey lighten-3 mr-2'"
                  :label="'Період з'"
                  day-type
                  block
              />
            </v-col>
            <v-col cols="6" sm="6" md="3">
              <MonthComponent
                  v-model="date_end"
                  :depressed="false"
                  :class_="'grey lighten-3 mr-2'"
                  :label="'Період по '"
                  day-type
                  block
              />
            </v-col>
            <v-col cols="6" sm="6" md="3">
              <v-btn class="white" block style="flex: 1;" min-height="35"
                     :disabled="!date_end || !date_start"
                     @click.stop="downloadPayments">
                {{ pays_end ? 'Оплати вже завантажені' : 'Завантажити оплати' }}
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="3">
              <v-btn class="white error--text text--darken-1" block style="flex: 1;" min-height="35"
                     @click.stop="dialog = true"
                     :disabled="!flat_errors.length"
              >
                Помилки
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="flex: 1">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="12" md="9">
            <v-card min-height="290">
              <v-card-text>
                <div class="pay-table">
                  <div class="pat-table-header">
                    <div class="pay-table-row">
                      <div class="col" style="flex: 0 0 50px;">
                        <v-checkbox color="success"
                                    hide-details
                                    class="pa-0 ma-0"
                                    :ripple="false"
                                    v-model="checked.all"
                                    @change="checkAll"
                        />
                      </div>
                      <div class="col" style="flex: 0 0 100px">Дата</div>
                      <div class="col" style="flex: 0 0 230px">Банк</div>
                      <div class="col" style="flex: 0 0 190px">Послуга</div>
                      <div class="col" style="flex: 0 0 90px">Сума</div>
                      <div class="col" style="flex: 0 0 90px">Сума без.</div>
                      <div class="col" style="flex: 0 0 70px">К-сть</div>
                      <div class="col" style="flex: 1"></div>
                    </div>
                  </div>
                  <div class="pay-table-body" v-if="items.length">
                    <div class="pay-table-row" v-for="(item, idx) in items" :key="idx" @click="setSelectRow(item)">
                      <div class="col" style="flex: 0 0 50px;">
                        <v-checkbox
                            color="success"
                            v-model="item.selected"
                            hide-details
                            class="pa-0 ma-0"
                            @click.stop=""
                            @change="changeSelect(item)"
                            :ripple="false"/>
                      </div>
                      <div class="col" style="flex: 0 0 100px">{{ item.pay_date | formatDate }}</div>
                      <div class="col" style="flex: 0 0 230px">{{ item.bank_name  }}</div>
                      <div class="col" style="flex: 0 0 190px">{{ item.service_name }}</div>
                      <div class="col" style="flex: 0 0 90px">{{ item.pay_sum | formatNumber }}</div>
                      <div class="col" style="flex: 0 0 90px">{{ item.pay_sum_without | formatNumber }}</div>
                      <div class="col" style="flex: 0 0 70px">{{ item.pay_count }}</div>
                      <div class="col" style="flex: 1"></div>
                    </div>
                  </div>
                  <div class="pay-table-body" v-else>
                    <div class="pay-table-row">
                      <div class="col"
                           style="flex: 1; font-size: 1.4rem;
                           font-weight: 400; line-height: 2.1rem;
                           color: #2d2d2d;
                           text-transform: uppercase;
                           padding-top: 45px; padding-bottom: 45px;
                           text-align: center">
                        Оплати ще не завантажені. <br>
                        Для завантаження виконайте команду <br>
                        ЗАВАНТАЖИТИ ОПЛАТИ
                      </div>
                    </div>
                  </div>
                  <div class="pat-table-footer">
                    <div class="pay-table-row">
                      <div class="col" style="flex: 0 0 570px;">
                        Всього:
                      </div>
                      <div class="col" style="flex: 0 0 90px">
                        {{ getTotal('items', 'pay_sum') | formatNumber }}
                      </div>
                      <div class="col" style="flex: 0 0 90px">
                        {{ getTotal('items', 'pay_sum_without') | formatNumber }}
                      </div>
                      <div class="col" style="flex: 0 0 70px">
                        {{ getTotal('items', 'pay_count') | formatNumber }}
                      </div>
                      <div class="col" style="flex: 1"></div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-btn small block color="grey lighten-5" class="mb-2 success--text" large
                   :disabled="!checked.count" @click.stop="createPayments">
              Провести обрані
            </v-btn>
            <v-btn small block color="grey lighten-5" large class="mb-2 error--text"
                   :disabled="!checked.count" @click.stop="deletePayments">
              Вилучити обрані
            </v-btn>
            <div class="total-card mb-2">
              <v-card class="d-flex flex-wrap">
                <div class="title">
                  Сума
                </div>
                <div class="c-value">
                  {{ checked.sum | formatNumber }}
                </div>
              </v-card>
            </div>
            <div class="total-card mb-2">
              <v-card class="d-flex flex-wrap">
                <div class="title">
                  Сума без ком.
                </div>
                <div class="c-value">
                  {{ checked.sum_without | formatNumber }}
                </div>
              </v-card>
            </div>
            <div class="total-card mb-2">
              <v-card class="d-flex flex-wrap">
                <div class="title">
                  К-сть квитанцій
                </div>
                <div class="c-value">
                  {{ checked.count }}
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rpcAPI from "@/utils/axios/eps"
import {getDateFromString, endOfMonth, getCurrentDate} from "@/utils/icons"
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {formatDate} from "@/filters";
import {QUESTION_SHOW} from "@/store/actions/question";

const modalDeleteId = 'accept_eps_payments_modal_id'

export default {
  name: "eps_payments",
  components: {
    MonthComponent: () => import("@/components/MonthComponent")
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonth',
      modalAnswer: 'question_answer'
    }),
    details_items() {
      if (this.selected_row) {
        return  this.rows.filter(item => {
          return item.bank_id === this.selected_row.bank_id
              && item.pay_date === this.selected_row.pay_date
              && item.service_id === this.selected_row.service_id
        })
      } else {
        return []
      }
    }
  },
  data() {
    return {
      date_start: null,
      date_end: null,
      download_date_start: null,
      download_date_end: null,
      items: [],
      rows: [],
      flat_errors: [],
      checked: {
        sum: 0,
        sum_without: 0,
        count: 0,
        groups: [],
        rows: [],
        all: false
      },
      detail_check_all: false,
      selected_row: null,
      modify_date: false,
      pays_end: false,
      pays_current_date: false,
      dialog: false,
      details: false
    }
  },
  methods: {
    setCheckedToDefault() {
      this.checked.count = 0
      this.checked.all = false
      this.checked.sum = 0
      this.checked.sum_without = 0
    },
    setSelectRow(payload) {
      this.selected_row = payload
      this.details = true
      this.detail_check_all = false
    },
    getTotal(table='items', col) {
      return this[table].reduce((acc, total) => {
        return acc + total[col]
      }, 0)
    },
    checkAll() {
      // this.checked.all = !this.checked.all
      this.items.forEach(item => {
        item.selected = this.checked.all
        item.items = []
      })
      this.rows.forEach(item => item.selected = this.checked.all)
      this.detail_check_all = false

      if (this.checked.all) {
        this.checked.sum = this.getTotal('items', 'pay_sum')
        this.checked.sum_without = this.getTotal('items', 'pay_sum_without')
        this.checked.count = this.getTotal('items', 'pay_count')
      } else {
        this.checked.sum = 0
        this.checked.sum_without = 0
        this.checked.count = 0
      }

    },
    detailCheckAllChange() {
      let count = 0
      let sum = 0
      let sum_without = 0

      this.details_items.forEach(item => {
        if (item.selected) {
          count -= item.pay_count
          sum -= item.pay_sum
          sum_without -= item.pay_sum_without
        }
      })

      this.details_items.forEach(item => {
        item.selected = this.detail_check_all
        if (item.selected) {
          count += item.pay_count
          sum += item.pay_sum
          sum_without += item.pay_sum_without
        }
      })
      this.selected_row.selected = this.detail_check_all
      this.selected_row.items = []
      this.checked.sum += sum
      this.checked.sum_without += sum_without
      this.checked.count += count
    },
    changeSelectDetail(payload) {
      const asign = payload.selected ? 1 : -1
      let checked_count = 0
      const items = []
      this.detail_check_all = false

      this.checked.sum += (payload.pay_sum * asign)
      this.checked.sum_without += (payload.pay_sum_without * asign)
      this.checked.count += (payload.pay_count * asign)


      this.$nextTick(() => {
        this.details_items.forEach(item => {
          checked_count += item.selected ? 1 : 0
          if (item.selected) {
            items.push(item.flat_id)
          }
        })

        this.selected_row.selected = checked_count > 0
        this.selected_row.items = items
      })
    },
    changeSelect(payload) {
      const filtered = this.rows.filter(item => {
        return item.bank_id === payload.bank_id
            && item.pay_date === payload.pay_date
            && item.service_id === payload.service_id
      })
      this.detail_check_all = false

      let count = 0
      let sum = 0
      let sum_without = 0
      let count_total = 0
      let sum_total = 0
      let sum_without_total = 0

      filtered.forEach(item => {
        if (item.selected) {
          count -= item.pay_count
          sum -= item.pay_sum
          sum_without -= item.pay_sum_without
        }
        item.selected = payload.selected
        if (item.selected) {
          count_total += (item.pay_count)
          sum_total += (item.pay_sum)
          sum_without_total += (item.pay_sum_without)
        }
      })

      if (payload.selected) {
        payload.items = []
      }

      this.checked.sum += sum_total + sum
      this.checked.sum_without += sum_without_total + sum_without
      this.checked.count += count_total + count

      if (this.checked.count === 0) {
        this.checked.all = false
      }
    },
    formatDate,
    getDateFromString,
    getTemporaryRows() {
      rpcAPI.temporary_rows()
          .then(response => response.data)
          .then(data => {
            const group = data.groups || []
            group.forEach(item => item.selected = false)
            const rows = data.rows || []
            rows.forEach(item => item.selected = false)
            this.items = group
            this.rows = rows
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    getPaymentsPeriod(get_rows=true) {
      rpcAPI.payments_period()
          .then(response => response.data)
          .then(data => {
            this.download_date_start = data.date_start
            this.download_date_end = data.date_end
            this.pays_end = false
            this.pays_current_date = false

            const current_month_js = getDateFromString(this.current_month)
            let current_date = getDateFromString(getCurrentDate())
            const current_date_minus_one = new Date(current_date.setDate(current_date.getDate() - 1))
            current_date = getDateFromString(getCurrentDate())
            let download_date_start = getDateFromString(this.download_date_end)
            const end_of_month = getDateFromString(endOfMonth(this.current_month))
            download_date_start = getDateFromString(this.download_date_end)

            let date_start, date_end

            if (download_date_start) {
              if (download_date_start >= end_of_month) {
                this.pays_end = true
                date_start = end_of_month
                date_end = end_of_month
              } else {
                const date_start_plus_one = new Date(download_date_start.setDate(download_date_start.getDate() + 1))
                if (date_start_plus_one > end_of_month) {
                  date_start = end_of_month
                  date_end = end_of_month

                  if (current_date_minus_one >= date_start && current_date_minus_one <= date_end) {
                    date_end = current_date_minus_one
                  }

                } else {
                  date_start = date_start_plus_one
                  date_end = end_of_month

                  if (current_date_minus_one >= date_start && current_date_minus_one <= date_end) {
                    date_end = current_date_minus_one
                  }

                  if (date_start.getTime() === current_date.getTime()) {
                    date_end = current_date
                    this.pays_current_date = true
                  }
                }
              }
            } else {
              date_start = current_month_js
              date_end = end_of_month
            }

            this.date_start = formatDate(date_start, 'YYYY-MM-DD')
            this.date_end = formatDate(date_end, 'YYYY-MM-DD')

            if (get_rows) {
              this.getTemporaryRows()
            }

          })
          // .catch(err => {
          //   const error = err.response.data.detail;
          //   this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          // })
    },
    downloadPayments(event, prevent_current=false) {
      if (!this.date_start || !this.date_end) {
        this.$store.commit(ALERT_SHOW, {text: 'Період з та Період по - мають бути заповнені', color: 'error'})
        return
      }

      if (this.pays_end) {
        const payload = {
          text: `Усі оплати за цей місяць уже завантажені. Повторне завантаження оплат може призвести до дублювання їх по абонентах`,
          accept_button: true,
          id: modalDeleteId
        }
        this.$store.dispatch(QUESTION_SHOW, payload)
        return;
      }

      const today = getCurrentDate()

      if (prevent_current === false) {
        if (this.date_start === today || this.date_end === today) {
          const payload = {
            text: `Не рекомендовано завантажувати оплати за поточний день, існує ймовірність оплати мешканців після
             того, як Ви їх завантажили (приклад: Ви завантажили оплати о 15:00, а мешканець оплатив о 20:00 - відповідно ця оплата не потрапить в завантажені).
              Продовжити завантаження оплат?`,
            accept_button: true,
            id: "download_payments_for_current_date"
          }
          this.$store.dispatch(QUESTION_SHOW, payload)
          return;
        }
      }

      rpcAPI.payments({date_start: this.date_start, date_end: this.date_end})
          .then(response => response.data)
          .then(data => {
            let message = ''
            this.items = data.data.groups
            this.rows = data.data.rows
            this.flat_errors = data.flat_error || []


            if (this.flat_errors.length) {
              message = 'Оплати завантажені із помилками... Перегляньте їх за допопомогою комади ПОМИЛКИ'
            }
            if (data.service_error) {
              let keys = Object.keys(data.service_error)
              if (keys.length) {
                message = 'Оплати не завантажені. Вкажіть коди послуг EPS у довіднику послуг ('
                keys.forEach(item => {
                  message += item
                })
                message += ').'
              }
            }
            if (!message) {
              message = 'Оплати завантажені успішно.'
              this.$store.commit(ALERT_SHOW, {text: message, color: 'success'})
            } else {
              this.$store.commit(ALERT_SHOW, {text: message, color: 'error lighten-1'})
            }
            this.getPaymentsPeriod(false)
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    createPayments() {
      const payload = {
        groups: this.items.filter(item => item.selected)
      }

      rpcAPI.create_payments(payload)
        .then(response => response.data)
        .then(data => {
          this.$store.commit(ALERT_SHOW, { text: 'Оплати успішно проведені', color: 'success' })

          const group = data.groups || []
          group.forEach(item => item.selected = false)
          this.items = group
          this.rows = data.rows || []

          this.setCheckedToDefault()
          this.getPaymentsPeriod(false)
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
        })
    },
    deletePayments() {
      const payload = {
        groups: this.items.filter(item => item.selected)
      }

      rpcAPI.delete_payments(payload)
          .then(response => response.data)
          .then(data => {
            this.$store.commit(ALERT_SHOW, { text: 'Оплати успішно вилучені', color: 'success' })

            const group = data.groups || []
            group.forEach(item => item.selected = false)
            this.items = group
            this.rows = data.rows || []

            this.setCheckedToDefault()
            this.getPaymentsPeriod(false)
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    }
  },
  created() {
  },
  watch: {
    current_month: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.getPaymentsPeriod()
        }
      }
    },
    modalAnswer: {
      handler(payload) {
        if (payload.id === modalDeleteId) {
          if (payload.answer) {
            this.pays_end = false
            this.downloadPayments(null, false)
          }
        }
        if (payload.id === 'download_payments_for_current_date') {
          if (payload.answer) {
            this.downloadPayments(null,true)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pay-table {
  .pat-table-header {
    .col {
      background: #f6f6f6;
      font-weight: 500;
      font-size: .8rem;
      padding-top: 5px;
      padding-bottom: 5px;
      overflow: hidden;
      white-space: nowrap;
    }

    .pay-table-row {
      .col {
        &:nth-child(1) {
          border-left: .5px solid #f6f6f6;
        }

        &:last-child {
          border-right: .5px solid #f6f6f6;
        }
      }
    }
  }

  .pay-table-body {
    max-height: 79vh;
    overflow: auto;

    .pay-table-row {
      .col {
        &:nth-child(1) {
          border-left: .5px solid #ececec;
        }

        &:last-child {
          border-right: .5px solid #ececec;
        }
      }

      &:hover .col {
        background: #f6f6f6;
      }
    }

    .col {
      background: #ffffff;
      font-size: .8rem;
      color: #2d2d2d;
      //border-bottom: .5px solid #ececec;
      padding-top: 2px;
      padding-bottom: 2px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .pat-table-footer {
    .col {
      background: #f1f1f1;
      color: #2d2d2d;
      font-weight: 500;
      font-size: .8rem;
      padding-top: 5px;
      padding-bottom: 5px;
      overflow: hidden;
      white-space: nowrap;
    }

    .pay-table-row {
      .col {
        &:nth-child(1) {
          border-left: .5px solid #f1f1f1;
        }

        &:last-child {
          border-right: .5px solid #f1f1f1;
        }
      }
    }
  }

  .pay-table-row {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
  }
}

.total-card {
  .title {
    flex: 50%;
    font-size: 1rem !important;
    font-weight: 500;
    background: rgb(245 245 245 / 93%);
    padding: 12px 4px 12px 8px;
    color: rgb(103 103 103 / 89%);
  }

  .c-value {
    flex: 50%;
    font-size: 1.3rem;
    padding: 12px 4px 12px 12px;
    line-height: 2rem;
  }
}
</style>